import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import HomeContainer from '../components/HomeContainer';

const IndexPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulHomePage {
          edges {
            node {
              primaryLinks {
                slug
                name
              }
              secondaryLinks {
                name
                slug
              }
              slot1 {
                __typename
                ... on Node {
                  ...CopyFragment
                  ...ServiceFragment
                  ...SpecialFragment
                  ...TestimonialFragment
                }
              }
              slot2 {
                __typename
                ... on Node {
                  ...CopyFragment
                  ...ServiceFragment
                  ...SpecialFragment
                  ...TestimonialFragment
                }
              }
              teaser {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout pageName='Home'>
        <HomeContainer
          heroImage={data.allContentfulHomePage.edges[0].node.teaser}
          primaryLinks={data.allContentfulHomePage.edges[0].node.primaryLinks}
          secondaryLinks={data.allContentfulHomePage.edges[0].node.secondaryLinks}
          slot1={data.allContentfulHomePage.edges[0].node.slot1}
          slot2={data.allContentfulHomePage.edges[0].node.slot2}
        />
      </Layout>
    )}
  />
);

export default IndexPage;
