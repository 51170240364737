import React from 'react';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import { Copy } from '../types/Copy';

const StyledCopy = styled(Paper)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1.1px;
  padding: 40px 24px 48px;
  position: relative;

  p {
    opacity: 0.8;
    text-align: left;
  }
`;

const Title = styled.div`
  font-family: 'Tangerine', cursive;
  font-size: 36px;
  line-height: normal;
  margin-bottom: 12px;
`;

function CopyCard({ content, title }: Copy) {
  return (
    <StyledCopy>
      {title && <Title>{title}</Title>}
      <div
        dangerouslySetInnerHTML={{
          __html: content.childMarkdownRemark.html,
        }}
      />
    </StyledCopy>
  );
}

export default CopyCard;
