import React from 'react';
import { Grid } from '@mui/material';

import Slot from './Slot';
import Button from './Button';
import HeroImage from './HeroImage';
import { Page } from '../types/Page';

interface PropTypes {
  heroImage: any;
  primaryLinks: Page[];
  secondaryLinks: Page[];
  slot1: any;
  slot2: any;
}

function HomeContainer({ heroImage, primaryLinks, secondaryLinks, slot1, slot2 }: PropTypes) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <HeroImage teaser={heroImage} />
      </Grid>
      <Grid item xs={12}>
        <Slot key='slot1' {...slot1} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent='space-evenly' rowSpacing={2}>
          {primaryLinks.map(({ name, slug }) => (
            <Grid key={slug} item xs={12} sm={6} textAlign='center'>
              <Button name={name} slug={slug} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Slot key='slot2' {...slot2} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent='space-evenly' rowSpacing={2}>
          {secondaryLinks.map(({ name, slug }) => (
            <Grid key={slug} item xs={12} sm={6} textAlign='center'>
              <Button name={name} slug={slug} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HomeContainer;
