import React from 'react';

import CopyCard from '../components/CopyCard';
import ServiceCard from '../components/ServiceCard';
import SpecialCard from '../components/SpecialCard';
import TestimonialCard from '../components/TestimonialCard';

interface PropTypes {
  __typename: string;
}

function Slot({ __typename, ...rest }: PropTypes) {
  switch (__typename) {
    case 'ContentfulCopy':
      return <CopyCard {...rest} />;
    case 'ContentfulService':
      return <ServiceCard {...rest} />;
    case 'ContentfulSpecial':
      return <SpecialCard {...rest} />;
    case 'ContentfulTestimonial':
      return <TestimonialCard {...rest} />;
    default:
      return null;
  }
}

export default Slot;
