import React from 'react';
import { Card } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledHeroImage = styled(Card)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const img = css`
  height: auto;
`;

function HeroImage({ teaser }) {
  return (
    <StyledHeroImage>
      <GatsbyImage key='heroImage' alt='Hero Image' css={img} image={teaser.gatsbyImageData} />
    </StyledHeroImage>
  );
}

export default HeroImage;
