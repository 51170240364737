import React from 'react';
import { Link } from 'gatsby';
import { Button } from '@mui/material';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 46.96%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) -2.84%, rgba(255, 255, 255, 0) 100%), #5da3ab;
  border: 2px solid white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 12px 36px;
`;

interface PropTypes {
  name: string;
  slug: string;
}

export default function JButton({ name, slug }: PropTypes) {
  return (
    <StyledButton component={Link} to={slug} variant='contained'>
      {name}
    </StyledButton>
  );
}
