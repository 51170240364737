import React from 'react';
import { Button, Card, CardActions, Grid } from '@mui/material';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Service } from '../types/Service';
import { mq } from '../breakpoints';

const StyledService = styled(Card)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1.1px;
  padding: 24px;
  position: relative;
  text-align: center;
`;
const Title = styled.div`
  font-family: 'Tangerine', cursive;
  font-size: 36px;
  line-height: normal;
  margin-bottom: 12px;
`;

const Wrapper = styled.div`
  p {
    margin-bottom: 12px;
    opacity: 0.8;
  }

  table {
    max-width: 400px;
    width: 100%;
  }
`;

const img = css`
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  min-height: 400px;

  ${mq.md(css`
    min-height: unset;
  `)}
`;

interface PropTypes extends Service {
  showLink?: boolean;
}

function ServiceCard({ description, title, photo, showLink = false, slug }: PropTypes) {
  const sizes = photo ? { xs: 12, sm: 6 } : { xs: 12 };

  return (
    <Grid container spacing={4}>
      <Grid id={slug} item {...sizes}>
        <StyledService>
          <Title>{title}</Title>
          <Wrapper
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
          {showLink && (
            <CardActions>
              <Button component={Link} fullWidth size='medium' to={`/services#${slug}`}>
                Learn more
              </Button>
            </CardActions>
          )}
        </StyledService>
      </Grid>
      {photo && (
        <Grid item {...sizes}>
          <GatsbyImage key='slug' alt={title} css={img} image={photo.gatsbyImageData} />
        </Grid>
      )}
    </Grid>
  );
}

export default ServiceCard;
